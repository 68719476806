/* eslint-disable jsx-a11y/anchor-is-valid */
// src/components/Header.jsx
import React from "react";
import "./Header.css";
import { motion } from "framer-motion";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
require("@solana/wallet-adapter-react-ui/styles.css");

const Header = ({ onOpenGratitude }) => {
  const handleScroll = (e, targetId) => {
    e.preventDefault();
    const target = document.getElementById(targetId);
    const headerHeight = document.querySelector(".header").offsetHeight;

    if (target) {
      const targetPosition =
        target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
      window.scrollTo({ top: targetPosition, behavior: "smooth" });
    }
  };

  const wallets = [new PhantomWalletAdapter()];
  const endpoint =
    "https://wandering-evocative-brook.solana-mainnet.quiknode.pro/9442a81fada35866c585b7306ec51ea4b0954f9e";

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <header className="header">
            <motion.div
              className="header-container"
              initial={{ y: -100 }}
              animate={{ y: 0 }}
              transition={{ type: "spring", stiffness: 50 }}
            >
              <div className="logo-section">
                <a
                  href="#home"
                  className="logo-text"
                  onClick={(e) => handleScroll(e, "home")}
                >
                  jitter
                  <span className="infinity-symbol">
                    <svg
                      className="infinity-svg"
                      viewBox="0 0 256 128"
                      width="32px"
                      height="16px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" strokeLinecap="round" strokeWidth="16">
                        <g className="infinity-track" stroke="#aaa">
                          <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
                          <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
                        </g>
                        <g strokeDasharray="180 656">
                          <path
                            className="infinity-worm1"
                            stroke="#fff"
                            d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"
                          />
                          <path
                            className="infinity-worm2"
                            stroke="#fff"
                            d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                  re
                </a>
              </div>
              <nav className="nav-menu">
                <a href="#about" onClick={(e) => handleScroll(e, "about")}>
                  About
                </a>
                <a href="#academy" onClick={(e) => handleScroll(e, "academy")}>
                  Courses
                </a>
                <a
                  href="#videocarousel"
                  onClick={(e) => handleScroll(e, "videocarousel")}
                >
                  Buy Reel
                </a>
                <a
                  href="#testimonials"
                  onClick={(e) => handleScroll(e, "testimonials")}
                >
                  Testimonials
                </a>
                <a href="#contact" onClick={(e) => handleScroll(e, "contact")}>
                  Contact
                </a>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onOpenGratitude();
                  }}
                >
                  Gratitude
                </a>
                <a
                  href="https://www.instagram.com/jittercore?igsh=Mm44MXdmdnYyb2Np"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Instagram"
                >
                  <img
                    src="/assets/inst.webp"
                    alt="Instagram"
                    className="social-icon2"
                  />
                  Instagram
                </a>
              </nav>
              <WalletMultiButton />
            </motion.div>
          </header>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default Header;
