import React from "react";
import styles from "./Main.module.css";
import { motion } from "framer-motion";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Main = () => {
  const handleScroll = (e, targetId) => {
    e.preventDefault();
    const target = document.getElementById(targetId);
    const header = document.querySelector(".header");

    if (header && target) {
      const headerHeight = header.offsetHeight;
      const targetPosition =
        target.getBoundingClientRect().top + window.pageYOffset - headerHeight;
      window.scrollTo({ top: targetPosition, behavior: "smooth" });
    } else {
      console.warn("Header or target element not found");
    }
  };

  return (
    <main className={styles.main} id="home">
      <motion.div
        className={styles.mainContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className={styles.leftSection}>
          <motion.h1
            initial={{ x: -100 }}
            animate={{ x: 0 }}
            transition={{ type: "spring", stiffness: 50 }}
          >
            Master the Inner Workings
          </motion.h1>
          <motion.p
            className={styles.descriptionText2}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Elevate your influence. Gain authentic followers. Transform your
            presence.
          </motion.p>
          <div className={styles.reelsContainer}>
            <div className={styles.reelWrapper}>
              <video
                className={styles.reel}
                src="assets/video3.mp4"
                autoPlay
                muted
                loop
                playsInline
              ></video>
              <div className={styles.viewsOverlay}>
                <FontAwesomeIcon icon={faEye} className={styles.eyeIcon} />
                7.2M
              </div>
            </div>
            <div className={styles.reelWrapper}>
              <video
                className={styles.reel}
                src="assets/bruni2.mp4"
                autoPlay
                muted
                loop
                playsInline
              ></video>
              <div className={styles.viewsOverlay}>
                <FontAwesomeIcon icon={faEye} className={styles.eyeIcon} />
                4.6M
              </div>
            </div>
            <div className={styles.reelWrapper}>
              <video
                className={styles.reel}
                src="assets/charm2.mp4"
                autoPlay
                muted
                loop
                playsInline
              ></video>
              <div className={styles.viewsOverlay}>
                <FontAwesomeIcon icon={faEye} className={styles.eyeIcon} />
                1.8M
              </div>
            </div>
          </div>
          <a
            href="#academy"
            onClick={(e) => handleScroll(e, "academy")}
            className={styles.ctaButton}
          >
            Explore the Academy →
          </a>
        </div>
      </motion.div>
      <div className="separator2"></div>

      {/* Обновленный раздел "Establishing Authority & Trust" */}
      <motion.div
        className={styles.tokenData}
        id="about"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <h2>Establishing Authority & Trust</h2>
        <div className={styles.aboutContent}>
          {/* Верхний текст */}
          <div className={styles.textTop}>
            <p>
              I’m Michael, a social media strategist who has spent years
              dissecting the algorithms that drive Instagram’s visibility. For
              over 5 years, I’ve worked alongside entrepreneurs, influencers,
              and emerging brands, helping them translate their creative vision
              into tangible growth—often in a matter of weeks.
            </p>
          </div>

          {/* Центральная секция с видео и боковым текстом */}
          <div className={styles.centerSection}>
            {/* Левая сторона текста */}
            <div className={styles.textLeft}>
              <p>
                My approach is rooted in data, trend analysis, and a nuanced
                understanding of how Instagram’s hidden metrics reward
                authenticity and consistency. Beyond numbers, I’ve also
                witnessed how harnessing the power of new mediums—such as
                AI-generated art—can capture fresh audiences and spark viral
                engagement.
              </p>
            </div>

            {/* Центральное видео */}
            <div className={styles.centerVideoWrapper}>
              <video
                className={styles.centerVideo}
                src="assets/trust.mp4"
                autoPlay
                muted
                loop
                playsInline
              ></video>
              <div className={styles.centerViewsOverlay}>
                <FontAwesomeIcon icon={faEye} className={styles.eyeIcon} />
                2.3M
              </div>
              <div className={styles.glow}></div>{" "}
              {/* Переместите свечение после видео */}
            </div>

            {/* Правая сторона текста */}
            <div className={styles.textRight}>
              <p>
                With a background that spans from boutique creative projects to
                global marketing campaigns, I’ve shaped a proven framework that
                positions you for sustainable growth. My clients benefit from
                personalized mentorship, round-the-clock support, and actionable
                roadmaps that adapt to the platform’s evolving landscape.
              </p>
            </div>
          </div>

          {/* Нижний текст */}
          <div className={styles.textBottom}>
            <p>
              Now, through carefully designed courses and personalized guidance,
              I’m sharing these insider techniques so you can achieve the kind
              of presence that stands out—without relying on empty shortcuts or
              fleeting gimmicks. This is about building a following that lasts,
              driven by quality content, strategic positioning, and the
              confidence that you’re always one step ahead of the algorithm.
            </p>
          </div>
        </div>
      </motion.div>
    </main>
  );
};

export default Main;
