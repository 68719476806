import React from "react";
import "./InfinityLoader.css";

function InfinityLoader() {
  return (
    <div className="unique-InfinityLoader">
      <svg
        className="unique-InfinityLoader-svg"
        viewBox="0 0 256 128"
        width="170.67px"
        height="85.33px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" strokeLinecap="round" strokeWidth="16">
          <g className="ip__track">
            <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
            <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
          </g>
          <g strokeDasharray="180 656">
            <path
              className="ip__worm1"
              d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"
            />
            <path
              className="ip__worm2"
              d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default InfinityLoader;