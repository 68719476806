import React from "react";
import "./Cancel.css";
import { TiCancel } from "react-icons/ti";


const Cancel = () => {
  return (
    <div className="cancel-container">
      <h1>Payment Cancelled</h1>
      <p>Your payment was not completed. If this was a mistake, please try again.</p>
      <a href="/" className="back-button">
      <TiCancel className="downIcon2" />  Back to Home
      </a>
    </div>
  );
};

export default Cancel;
