import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Testimonials from "./components/Testimonials";
import InfinityLoader from "./components/InfinityLoader";
import Separator from "./components/Separator";
import Courses from "./components/Courses";
import Success from "./components/Success";
import Cancel from "./components/Cancel";
import VideoCarousel from "./components/VideoCarousel";
import GratitudeModal from "./components/GratitudeModal";
import DetailsModal from "./components/DetailsModal";
import { initGA, logPageView } from "./analytics";
import { Toaster, toast } from "sonner";

import "./App.css";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isGratitudeOpen, setIsGratitudeOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  useEffect(() => {
    initGA();

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      toast(
        (t) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px",
              position: "relative",
            }}
          >
            <div>
              <div style={{ fontWeight: "bold", marginBottom: "8px" }}>
                This project is for sale, Instagram account and ready business
                model.
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
              >
                <img
                  src="/assets/tg.png"
                  alt="Telegram Icon"
                  style={{ width: "18px", height: "18px" }}
                />
                <a
                  href="https://t.me/reduxc"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#1E90FF",
                    textDecoration: "none",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  @reduxc
                </a>
                <img
                  src="/assets/inst.webp"
                  alt="Instagram Icon"
                  style={{ width: "18px", height: "18px", marginLeft: "5px" }}
                />
                <a
                  href="https://instagram.com/addictcontent"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#1E90FF",
                    textDecoration: "none",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  @addictcontent
                </a>
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  marginTop: "20px",
                  fontSize: "12px",
                  color: "grey",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                onClick={() => {
                  toast.dismiss();
                  setIsDetailsOpen(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                More details
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "-10px",
                right: "5px",
                cursor: "pointer",
                color: "#666",
                fontSize: "25px",
              }}
              onClick={() => {
                toast.dismiss();
                document.body.style.overflow = "hidden";
              }}
            >
              &times;
            </div>
            <img
              src="https://img.icons8.com/ios-filled/50/ffffff/sale.png"
              alt="Sale Icon"
              style={{ width: "24px", height: "24px" }}
            />
          </div>
        ),
        {
          style: {
            border: "none",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            backgroundColor: "#333",
            color: "#fff",
            borderRadius: "8px",
            fontSize: "16px",
            padding: "16px",
            position: "relative",
            overflow: "hidden",
          },
          duration: 30000,
        }
      );
    }
  }, [isLoading, setIsDetailsOpen]);

  if (isLoading) {
    return <InfinityLoader />;
  }

  const openGratitudeModal = () => {
    setIsGratitudeOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeGratitudeModal = () => {
    setIsGratitudeOpen(false);
    document.body.style.overflow = "auto";
  };

  const closeDetailsModal = () => {
    setIsDetailsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <Router>
      <Analytics />
      <div className="App">
        <Header onOpenGratitude={openGratitudeModal} />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Main />
                <Separator />
                <VideoCarousel />
                <Separator />
                <Testimonials />
                <Separator />
                <Courses />
                <Separator />
              </>
            }
          />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
        <Footer />
        <Toaster
          toastOptions={{
            className: "sonner-toast",
          }}
        />
        <GratitudeModal
          isOpen={isGratitudeOpen}
          onClose={closeGratitudeModal}
        />
        <DetailsModal isOpen={isDetailsOpen} onClose={closeDetailsModal} />
      </div>
    </Router>
  );
};

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);

  return null;
};

export default App;
