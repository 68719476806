/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { FaQuestionCircle, FaCheck } from "react-icons/fa";
import { toast } from "sonner";
import "./VideoCarousel.css";
import { motion, AnimatePresence } from "framer-motion";

// Функция для генерации уникального токена (как в Courses)
const generateToken = () => {
  return btoa(`order-${Date.now()}-${Math.random()}`);
};

const VideoCarousel = () => {
  const videos = [
    { src: "/assets/ex1.mp4", alt: "Example video 1" },
    { src: "/assets/ex2.mp4", alt: "Example video 2" },
    { src: "/assets/ex3.mp4", alt: "Example video 3" },
    { src: "/assets/ex4.mp4", alt: "Example video 4" },
    { src: "/assets/ex5.mp4", alt: "Example video 5" },
  ];

  // Цена в долларах — фиксированная для примера
  const price = 129.0;
  const merchantId = "3674a70f6f7ed4e366f374672f989cb1";
  const currency = "USD";

  // Генерируем токен один раз при рендере
  const [token, setToken] = useState(generateToken());

  // ---- Модалка согласия ----
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [isConsentConfirmed, setIsConsentConfirmed] = useState(false);
  const [isConsentPermission, setIsConsentPermission] = useState(false);

  // ---- Активируем кнопку "Order" только если пользователь согласился со всеми условиями ----
  const [isOrderActive, setIsOrderActive] = useState(false);

  // ---- Модалка о длительности видео ----
  const [isDurationModalOpen, setIsDurationModalOpen] = useState(false);

  // Открыть/закрыть модалку согласия
  const openConsentModal = () => {
    setIsConsentModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeConsentModal = () => {
    setIsConsentModalOpen(false);
    document.body.style.overflow = "auto";
  };

  // Клик по кнопке "Confirm" внутри модалки согласия
  const handleConsentConfirm = () => {
    if (isConsentConfirmed && isConsentPermission) {
      setIsOrderActive(true); // теперь можно покупать
      toast.success("You have agreed to the terms!");
      closeConsentModal();
    } else {
      toast.error("Please agree to all the terms to proceed.");
    }
  };

  // Открыть/закрыть модалку длительности
  const openDurationModal = () => {
    setIsDurationModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeDurationModal = () => {
    setIsDurationModalOpen(false);
    document.body.style.overflow = "auto";
  };

  // Закрытие модалок по ESC
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        closeConsentModal();
        closeDurationModal();
      }
    };

    if (isConsentModalOpen || isDurationModalOpen) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [isConsentModalOpen, isDurationModalOpen]);

  return (
    <section id="videocarousel" className="video-carousel">
      <h2 className="carousel-title">Collaboration with Michael</h2>
      <div className="video-container">
        {videos.map((video, index) => (
          <video
            key={index}
            className="carousel-video"
            src={video.src}
            autoPlay
            muted
            loop
            playsInline
            alt={video.alt}
          />
        ))}
      </div>

      <div className="price-section-transform">
        <p className="price-display-transform">${price}</p>
        <div className="duration-display-container">
          <p className="duration-display">20 seconds</p>
          <FaQuestionCircle
            className="duration-info-icon text-gray-400 cursor-pointer"
            onClick={openDurationModal}
          />
        </div>
      </div>

      {/* Блок с двумя кнопками: Order Now + Buy Me a Coffee */}
      <div className="order-buttons-container">
        {/* Кнопка 1: Order Now (через CoinPayments) */}
        <form
          action="https://www.coinpayments.net/index.php"
          method="post"
          target="_top"
          onSubmit={(e) => {
            if (!isOrderActive) {
              e.preventDefault();
              openConsentModal();
            }
          }}
        >
          <input type="hidden" name="cmd" value="_pay_simple" />
          <input type="hidden" name="reset" value="1" />
          <input type="hidden" name="merchant" value={merchantId} />
          <input type="hidden" name="currency" value={currency} />
          <input type="hidden" name="amountf" value={price} />
          <input
            type="hidden"
            name="item_name"
            value="AI Video Transformation"
          />
          <input
            type="hidden"
            name="success_url"
            value={`https://t.me/jittercore?token=${token}`}
          />
          <input
            type="hidden"
            name="cancel_url"
            value="https://jittercore.art/cancel"
          />
          <input type="hidden" name="custom" value={token} />

          <button
            type="submit"
            className={`order-button bg-white text-gray-900 font-semibold py-2.5 px-4 rounded-lg hover:bg-gray-300 font-poppins-medium ${
              isOrderActive ? "active-order-button" : ""
            }`}
          >
            Order Now
          </button>
        </form>

        {/* Кнопка 2: Buy Me a Coffee (просто ссылка) */}
        <a
          href="https://buymeacoffee.com/jittercore/e/350732"
          target="_blank"
          rel="noopener noreferrer"
          className="bmc-button-link2"
        >
          <img
            src="/assets/bmc.png"
            alt="Buy Me a Coffee"
            className="bmc-button-img2"
          />
        </a>
      </div>

      <p className="note-text">
        Note: After payment, you will be redirected to a Telegram chat to
        provide your requirements and upload your video for transformation.
      </p>

      {/* ---------- Consent Modal ---------- */}
      <AnimatePresence>
        {isConsentModalOpen && (
          <motion.div
            className="unique-consent-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeConsentModal}
          >
            <motion.div
              className="unique-consent-modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="modal-arrow-down"></div>
              <h3 className="consent-modal-title">Terms and Consent</h3>
              <div className="consent-modal-body">
                <p className="consent-modal-text">
                  <strong className="titleAgree">Copyright Confirmation</strong>
                </p>
                <p className="consent-modal-text">
                  I confirm that the content I am providing is my own or I have
                  all rights to use this content and permit its modification.
                </p>

                <p className="consent-modal-text">
                  <strong className="titleAgree">Permission for Use</strong>
                </p>
                <p className="consent-modal-text">
                  I grant permission for the use and modification of the
                  provided content for publication on the Instagram account as
                  part of the AI Video Transformation service.
                </p>

                <p className="consent-modal-text">
                  <strong className="titleAgree">
                    Responsibility for Third-Party Content
                  </strong>
                </p>
                <p className="consent-modal-text">
                  I confirm that the content I have provided does not infringe
                  upon third-party copyrights and take full responsibility for
                  any claims related to the use of this content.
                </p>

                <p className="consent-modal-text">
                  <strong className="titleAgree">Terms of Use</strong>
                </p>
                <ul className="consent-modal-list">
                  <li>
                    The content will be used solely for the purposes of the AI
                    Video Transformation service.
                  </li>
                  <li>
                    I understand that the provided content may be altered using
                    AI and agree to such modifications.
                  </li>
                  <li>
                    I understand that no additional compensation will be
                    provided for the use of the content.
                  </li>
                  <li>
                    I understand that the artist may refuse to create a video
                    for ethical or moral reasons.
                  </li>
                </ul>

                <div className="consent-checkbox-group">
                  <div className="consent-checkbox-item">
                    <input
                      type="checkbox"
                      id="consent-confirm"
                      checked={isConsentConfirmed}
                      onChange={(e) => setIsConsentConfirmed(e.target.checked)}
                    />
                    <label
                      htmlFor="consent-confirm"
                      className="consent-checkbox-text"
                    >
                      I confirm that I have provided accurate information and
                      agree to the terms.
                    </label>
                  </div>
                  <div className="consent-checkbox-item">
                    <input
                      type="checkbox"
                      id="consent-permission"
                      checked={isConsentPermission}
                      onChange={(e) => setIsConsentPermission(e.target.checked)}
                    />
                    <label
                      htmlFor="consent-permission"
                      className="consent-checkbox-text"
                    >
                      I grant permission for the use and modification of the
                      provided content in accordance with the above conditions.
                    </label>
                  </div>
                </div>

                <button
                  className={`consent-confirm-button ${
                    isConsentConfirmed && isConsentPermission
                      ? "enabled"
                      : "disabled"
                  }`}
                  onClick={handleConsentConfirm}
                  disabled={!(isConsentConfirmed && isConsentPermission)}
                >
                  <FaCheck className="check-icon" /> Confirm
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* ---------- Duration Info Modal ---------- */}
      <AnimatePresence>
        {isDurationModalOpen && (
          <motion.div
            className="unique-duration-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeDurationModal}
          >
            <motion.div
              className="unique-duration-modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="duration-modal-title">Detailed Information</h3>
              <p className="duration-modal-description">
                You can commission a fully customized video from Michael based
                on any description you provide. He’ll craft a one-of-a-kind
                piece using your original footage and ideas. Although
                Instagram’s policies limit the scope of AI-driven video
                generation, there are essentially no creative boundaries to what
                you can request. The final product—usually around 20 seconds in
                length—can be transformed in any way you envision. Moreover,
                with the listed price, you acquire full rights to the finished
                video, allowing you to share or market it however you wish. Once
                you place your order, you’ll be redirected to a chat where you
                can finalize the details and bring your vision to life.
              </p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default VideoCarousel;
