// src/components/Testimonials.jsx
import React from "react";
import "./Testimonials.css";
import { faEye, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const testimonialsData = [
  {
    avatar: "assets/avatar1.jpg",
    username: "brunir4x",
    followers: "273K",
    growthFollowers: "105K",
    videoSrc: "/assets/bruni.mp4",
    text: "“I finally understand how the algorithm rewards niche authority and consistent content. My brand’s aesthetic, paired with AI-generated visuals, sparked viral engagement. I saw a 300% increase in impressions and top-tier influencer collaborations.”",
    views: "10.1M",
    growthViews: "220M",
    link: "https://www.instagram.com/brunirax.digitalart/",
  },
  {
    avatar: "/assets/guy.webp",
    username: "charmbustersofc",
    followers: "21K",
    growthFollowers: "18K",
    text: "“Before working with Michael, I struggled to break 1,000 followers. After applying the insights, my content reached a whole new audience. I now have over 15,000 engaged followers and steady sales leads directly from my profile.”",
    videoSrc: "/assets/charmbustersofc.mp4",
    views: "5.8M",
    growthViews: "32M",
    link: "https://www.instagram.com/charmbustersofc/",
  },
  {
    avatar: "/assets/women.avif",
    username: "ai.creator",
    followers: "115K",
    growthFollowers: "57K",
    text: "“This isn’t about quick hacks—it’s about understanding how Instagram really works. I’ve seen organic growth that far outperforms any paid ads I’ve tried. Now I have a genuinely interested community and steady conversions.”",
    videoSrc: "/assets/ua.mp4",
    views: "912K",
    growthViews: "90.5M",
    link: "https://www.instagram.com/aicreator_ua/",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials" className="testimonials-section">
      <h2>Real Client Results &amp; Testimonials</h2>
      <div className="testimonials-container">
        {testimonialsData.map((t, index) => (
          <div className="testimonial-card" key={index}>
            <div className="left-part">
              <a
                href={t.link}
                target="_blank"
                rel="noopener noreferrer"
                className="clickable-link avatar-link"
              >
                <div className="avatar-wrapper">
                  <img
                    src={t.avatar}
                    alt={t.username}
                    className="testimonial-avatar"
                  />
                </div>
              </a>
              <a
                href={t.link}
                target="_blank"
                rel="noopener noreferrer"
                className="clickable-link username-link"
              >
                <div className="testimonial-username">
                  {t.username}
                  {index !== 0 && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="verified-icon"
                    />
                  )}
                </div>
              </a>
              <div className="testimonial-followers">
                {t.followers} followers
              </div>
              <p className="testimonial-text">{t.text}</p>
            </div>
            <div className="right-part">
              <a
                href={t.link}
                target="_blank"
                rel="noopener noreferrer"
                className="clickable-link video-link"
              >
                <div className="video-wrapper">
                  <video
                    className="testimonial-video"
                    src={t.videoSrc}
                    autoPlay
                    muted
                    loop
                    playsInline
                  ></video>
                  <div className="viewsOverlay">
                    <FontAwesomeIcon icon={faEye} className="eyeIcon" />
                    {t.views}
                  </div>
                </div>
              </a>
              <div className="growth-data">
                <div className="followers-growth">
                  +{t.growthFollowers} followers
                </div>
                <div className="views-growth">+{t.growthViews} views</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
