import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./DetailsModal.css";

const DetailsModal = ({ isOpen, onClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="details-modal-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <motion.div
            className="details-modal-content"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="modal-title">Account & Business Overview</h2>

            <div className="details-section">
              <h3 className="section-title">Account Information</h3>
              <p className="section-item">
                <strong>Followers:</strong> 100k (growing steadily)
              </p>
              <p className="section-item">
                <strong>Total Views:</strong> Over 150 million
              </p>
              <p className="section-item">
                <strong>Demographics:</strong> 30% American audience, 75% male, 50% aged 18-32
              </p>
            </div>

            <div className="details-section">
              <h3 className="section-title">Business Model</h3>
              <p className="section-item">Course sales</p>
              <p className="section-item">Paid collaborations and mentions</p>
              <p className="section-item">Reposts and story promotions</p>
              <p className="section-item">
                Advertisements generating <strong>$750–$1,500/month</strong>
              </p>
              <p className="section-item">Corporate email setup</p>
              <p className="section-item">Ad exchange platforms</p>
              <p className="section-item">Donation tools (e.g., BuyMeACoffee)</p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DetailsModal;
