import React, { useState, useEffect } from "react";
import { FaCheck, FaQuestionCircle } from "react-icons/fa";
import "./Courses.css";
import { motion, AnimatePresence } from "framer-motion";

const plans = [
  {
    name: "Base",
    price: 29.0,
    discount: 20,
    descriptionShort: "Ideal for beginners.",
    benefitsShort: [
      "Data Insights",
      "AI Growth",
      "Easy Start",
      "Guaranteed 10K followers",
      "Guaranteed 10M views",
    ],
    fullInfo: `
Ideal for beginners who need a clear starting point.

What you get?

- Step-by-step algorithm decoding.
- Basics of content creation and engagement strategies.
- Introduction to AI-art aesthetics to diversify your visuals.
- PDF guides with detailed steps.
- Email support for troubleshooting your first steps.

Followers: ~10K
Views: ~10M`,
    type: "base",
    currency: "USD",
    merchantId: "3674a70f6f7ed4e366f374672f989cb1",
    // <-- Добавляем персональную ссылку на Buy Me A Coffee
    bmcLink: "https://buymeacoffee.com/jittercore/e/349981",
  },
  {
    name: "Premium",
    price: 55.0,
    discount: 20,
    descriptionShort: "For established entrepreneurs.",
    benefitsShort: [
      "Brand Boost",
      "Expert Mentors",
      "VIP Access",
      "Guaranteed 25K followers",
      "Guaranteed 45M views",
    ],
    fullInfo: `
For established entrepreneurs and influencers looking to transform their profile into a recognized authority.

What you get?

- Custom-tailored growth strategy blending brand identity, AI-driven creativity, and audience psychology.
- Comprehensive audit of your existing content and follower engagement.
- Unlimited mentorship sessions and personal guidance for high-impact collaborations.
- Priority updates on algorithm changes and insider tactics to stay ahead of the curve.
- Instructions, PDF guides, and access to an exclusive chat.

Followers: ~25K
Views: ~35M`,
    type: "premium",
    currency: "USD",
    merchantId: "3674a70f6f7ed4e366f374672f989cb1",
    // <-- Уникальная ссылка для Premium
    bmcLink: "https://buymeacoffee.com/jittercore/e/349984",
  },
  {
    name: "Advantage",
    price: 179.0,
    discount: 20,
    descriptionShort: "Amplify your presence.",
    benefitsShort: [
      "Trend Analysis",
      "Audience Reach",
      "Story Impact",
      "Guaranteed 100K followers",
      "Guaranteed 125M views",
    ],
    fullInfo: `
Perfect for those who already have an audience but want to significantly amplify their presence.

What you get?

- Deep-dive into data analytics for content optimization.
- Advanced storytelling techniques and audience segmentation.
- VIP access to AI-art resources and trend forecasting sessions.
- One-on-one consultation calls and 24/7 priority mentorship until key milestones are achieved.
- Instructions, PDF guides, and access to an exclusive chat.

Followers: ~100K
Views: ~125M`,
    type: "advantage",
    currency: "USD",
    merchantId: "3674a70f6f7ed4e366f374672f989cb1",
    // <-- Уникальная ссылка для Advantage
    bmcLink: "https://buymeacoffee.com/jittercore/e/349985",
  },
];

const Courses = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const generateToken = (planName) => {
    return btoa(`${planName}-${Date.now()}-${Math.random()}`);
  };

  const openModal = (plan) => {
    setSelectedPlan(plan);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setSelectedPlan(null);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    if (selectedPlan) {
      window.addEventListener("keydown", handleEsc);
    }

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [selectedPlan]);

  return (
    <section id="academy" className="py-10 px-4">
      <h2 className="text-3xl font-bold text-center mb-20 text-white font-poppins-bold">
        The Academy
      </h2>

      <div className="flex flex-wrap justify-center gap-10">
        {plans.map((plan, index) => {
          const oldPrice = (plan.price * 1.2).toFixed(2);
          const discountedPrice = plan.price.toFixed(0);
          const token = generateToken(plan.name);

          return (
            <div
              key={index}
              className="courses-plan-card relative w-72 rounded-xl p-6 bg-white/10 backdrop-blur-md shadow-md text-white flex flex-col gap-4 z-10 transform transition-transform duration-300 hover:scale-105"
            >
              {plan.type === "premium" && (
                <div className="courses-plan-popular-label absolute top-4 right-4 bg-white text-black text-xs font-bold py-1 px-2 rounded-xl">
                  Popular
                </div>
              )}

              {/* Название плана и иконка вопроса */}
              <div className="courses-plan-header flex items-center justify-between">
                <div className="courses-plan-title-container flex items-center gap-2">
                  <h3 className="courses-plan-name text-xl font-medium font-poppins-medium">
                    {plan.name}
                  </h3>
                  <FaQuestionCircle
                    className="courses-plan-info-icon text-gray-400 cursor-pointer"
                    onClick={() => openModal(plan)}
                  />
                </div>
              </div>

              {/* Цены */}
              <div className="price-section">
                <span className="new-price">${discountedPrice}</span>
                <span className="old-price">${oldPrice}</span>
              </div>

              <p className="text-sm text-gray-300 whitespace-pre-line font-poppins-light">
                {plan.descriptionShort}
              </p>

              {/* Список коротких бенефитов */}
              <ul className="flex flex-col gap-2">
                {plan.benefitsShort.map((benefit, i) => (
                  <li key={i} className="flex items-center gap-2">
                    <FaCheck className="text-green-500" />
                    <span className="font-poppins-medium">{benefit}</span>
                  </li>
                ))}
              </ul>

              {/* Блок с двумя кнопками горизонтально */}
              <div className="mt-6 flex flex-row items-center justify-center gap-2">
                {/* Кнопка оплаты криптой */}
                <form
                  action="https://www.coinpayments.net/index.php"
                  method="post"
                  target="_top"
                  className="flex justify-center"
                >
                  <input type="hidden" name="cmd" value="_pay_simple" />
                  <input type="hidden" name="reset" value="1" />
                  <input
                    type="hidden"
                    name="merchant"
                    value={plan.merchantId}
                  />
                  <input type="hidden" name="currency" value={plan.currency} />
                  <input type="hidden" name="amountf" value={discountedPrice} />
                  <input type="hidden" name="item_name" value={plan.name} />
                  <input
                    type="hidden"
                    name="success_url"
                    value={`https://jittercore.art/success?token=${token}`}
                  />
                  <input
                    type="hidden"
                    name="cancel_url"
                    value="https://jittercore.art/cancel"
                  />
                  <input type="hidden" name="custom" value={token} />
                  <button
                    type="submit"
                    className="bg-white text-gray-900 font-semibold py-2.5 px-4 rounded-lg hover:bg-gray-300 transition-colors duration-300 flex items-center justify-center font-poppins-medium"
                  >
                    Buy with crypto
                  </button>
                </form>

                {/* Альтернативная оплата Buy Me a Coffee (у каждой своя ссылка) */}
                <a
                  href={plan.bmcLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bmc-button-wrapper"
                >
                  <img
                    src="/assets/bmc.png"
                    alt="Buy Me A Coffee"
                    className="bmc-button-img"
                  />
                </a>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-12 text-center text-white font-poppins-light px-4">
        <p className="explanation-text">
          Note: Once you purchase the plan you choose, an additional window will
          open with a link to download the materials. Depending on your plan,
          you will also gain access to other features, such as communication
          with participants and mentors, including Michael, who will provide
          updates and guidance. These materials will be available without
          restrictions for those who have purchased the course once.
        </p>
      </div>

      {/* Модальное окно детальной информации о курсе */}
      <AnimatePresence>
        {selectedPlan && (
          <motion.div
            className="unique-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeModal}
          >
            <motion.div
              className="unique-modal-content"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="courses-modal-title">{selectedPlan.name}</h3>
              <p className="courses-modal-description">
                {selectedPlan.fullInfo}
              </p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Courses;
