// supportersList.js

export const supportersList = [
    { nick: "alex1987qq@gmail.com",         amount: "0.03 BTC" },
    { nick: "@crystal_meme",           amount: "$150" },
    { nick: "kevinNFT",                amount: "2 MATIC" },
    { nick: "johnny_btc",              amount: "0.002 BTC" },
    { nick: "dasha@crypto.org",        amount: "1.2 SOL" },
    { nick: "moonrider88",             amount: "0.5 ETH" },
    { nick: "@ruby_red",               amount: "$300" },
    { nick: "devilcat@hotmail.com",    amount: "$75" },
    { nick: "@gatsby",                 amount: "75 USDT" },
    { nick: "wildWest",                amount: "150 USDT" },
    { nick: "georgia@mail.com",        amount: "$20" },
    { nick: "mrRobot",                 amount: "0.01 BTC" },
    { nick: "@pixelVibes",             amount: "15 MATIC" },
    { nick: "sarah.jones@gmail.com",   amount: "$85" },
    { nick: "elenaCrypto",             amount: "3.3 MATIC" },
    { nick: "@prince_of_eth",          amount: "1 ETH" },
    { nick: "music4life",              amount: "$10" },
    { nick: "ivan@metaworld.net",      amount: "200 USDT" },
    { nick: "dancerQueen",             amount: "0.8 SOL" },
    { nick: "happyFeet99",             amount: "0.005 BTC" },
    { nick: "@fancyPlants",            amount: "$220" },
    { nick: "markZ",                   amount: "0.02 BTC" },
    { nick: "lily-lotus",              amount: "0.5 ETH" },
    { nick: "samuraiCoder",            amount: "100 USDT" },
    { nick: "whiteRabbit",             amount: "250 USDT" },
    { nick: "bigFish",                 amount: "$175" },
    { nick: "sergey@trustwallet.io",   amount: "0.9 SOL" },
    { nick: "blockchainLass",          amount: "0.03 ETH" },
    { nick: "@tokenTim",               amount: "$45" },
    { nick: "ameliaCodes",             amount: "30 MATIC" },
    { nick: "nftGorilla",              amount: "0.004 BTC" },
    { nick: "@coffeeFiend",            amount: "45 USDT" },
    { nick: "ralf.dev",                amount: "$500" },
    { nick: "rainbowSlytherin",        amount: "0.25 SOL" },
    { nick: "slimShady@meme.com",      amount: "2.5 MATIC" },
    { nick: "marta_flowers",           amount: "0.01 ETH" },
    { nick: "curiousGeorge99",         amount: "$99" },
    { nick: "chefAntonio",             amount: "$250" },
    { nick: "dashaNova",               amount: "400 USDT" },
    { nick: "@starGazer",              amount: "1.75 SOL" },
    { nick: "nancyDrew@mail.ua",       amount: "0.5 MATIC" },
    { nick: "gorillaGrip",             amount: "$15" },
    { nick: "unicornDreamer",          amount: "0.2 ETH" },
    { nick: "@chillout708",            amount: "$180" },
    { nick: "catMommy",                amount: "80 USDT" },
    { nick: "vitaly@maticlabs.co",     amount: "10 MATIC" },
    { nick: "goldenBoy",               amount: "0.03 BTC" },
    { nick: "robinTheHood",            amount: "$360" },
    { nick: "xXfarmerJoeXx",           amount: "1.1 SOL" },
    { nick: "@silverline",             amount: "0.4 ETH" },
  ];
  